import type { ReactNode } from 'react'
import { useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { httpBatchLink, loggerLink } from '@trpc/client'
import type { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'
import superjson from 'superjson'

import { env } from '@aqua/env'
import { removeProtocol } from '@aqua/shared'

import { trpc } from '~/utils/api'
import { getBaseUrl } from './functions'

export const DataProviders = (props: {
  children: ReactNode
  session: Session | null
}) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 5 * 1000,
            retry: false,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
          },
        },
      }),
  )

  const [trpcClient] = useState(() =>
    trpc.createClient({
      transformer: superjson,
      links: [
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === 'development' ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
          headers() {
            return {
              'x-trpc-source': 'nextjs-react',
            }
          },
        }),
      ],
    }),
  )

  return (
    <SessionProvider refetchOnWindowFocus={false} session={props.session}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          {props.children}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </trpc.Provider>
    </SessionProvider>
  )
}

/**
 * Put the app providers inside data
 *
 */
export const AppProviders = (props: { children: React.ReactNode }) => {
  return props.children
}
