import type { CategoryWithChildren } from '@aqua/db/types'
import { env } from '@aqua/env'

export const getBaseUrl = () => {
  if (typeof window !== 'undefined') return '' // browser should use relative url
  if (env.NEXT_PUBLIC_URL) return env.NEXT_PUBLIC_URL // SSR should use vercel url

  return `http://localhost:3000` // dev SSR should use localhost
}

export const getCategoryNameList = (
  category: CategoryWithChildren,
  selectedCategories: string[],
) => {
  const selectedNames: string[] = []

  const calculateRecursively = (category: CategoryWithChildren) => {
    category.children?.forEach((childCategory) => {
      if (selectedCategories.includes(String(childCategory.id))) {
        selectedNames.push(childCategory.name!)
      }
      if (childCategory.children?.length) {
        calculateRecursively(childCategory)
      }
    })
  }

  calculateRecursively(category)

  return selectedNames
}

export function convertUndefinedToNull(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    // If obj is an array, recursively apply the function to each element
    return obj.map((item: any) => convertUndefinedToNull(item))
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'undefined') {
        obj[key] = null
      } else if (typeof obj[key] === 'object') {
        obj[key] = convertUndefinedToNull(obj[key])
      }
    }
  }

  return obj
}
