import { Toaster } from '@/components/ui/toaster'

import '../styles/globals.css'

import type { AppType } from 'next/dist/shared/lib/utils'
import { Inter } from 'next/font/google'
import type { Session } from 'next-auth'

import { AppProviders, DataProviders } from '~/utils/Providers'

const inter = Inter({ subsets: ['latin'] })
const App: AppType<{ session: Session | null }> = ({
  Component,
  pageProps,
}) => {
  return (
    <DataProviders session={pageProps.session}>
      <AppProviders>
        <style jsx global>{`
          html {
            font-family: ${inter.style.fontFamily};
          }
        `}</style>
        <Component {...pageProps} />
        <Toaster />
      </AppProviders>
    </DataProviders>
  )
}
export default App
